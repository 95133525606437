




















































































import { Component, Vue } from "vue-property-decorator";

@Component
export default class NavBar extends Vue {
  logo = require("@/assets/logo.png");

  navDrawer = null;

  menuItems = [
    {
      title: "ONLINE COURSES",
      to: {
        name: "course-catalog-en"
      }
    },
    {
      title: "ABOUT US",
      to: {
        name: "about-us-en"
      }
    },
    {
      title: "OUR PARTNERS",
      to: {
        name: "about-us-en",
        hash: "#ourpartners"
      }
    },
    {
      title: "FAQ",
      to: {
        name: "about-us-en",
        hash: "#faq"
      }
    }
  ];

  dialog = false;

  get userSignedIn() {
    return this.$store.getters["auth/userSignedIn"];
  }
}
