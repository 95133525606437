<template>
  <v-alert
    v-model="alert"
    close-label="Kapat"
    color="accent"
    dark
    absolute
    class="cookie"
    rounded="0"
  >
    <v-container>
      <v-row no-gutters align="center">
        <v-col cols="12" sm="10">
          By using our site, you consent to the use of cookies. Please find out more on our
          <router-link :to="{ name: 'privacy-policy' }"
            >Privacy Policy</router-link
          >
          page.
        </v-col>
        <v-col cols="12" sm="2" class="text-right">
          <v-btn color="primary" @click="alert = false" small elevation="0">
            Close
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-alert>
</template>

<script>
export default {
  data() {
    return {
      alert: true
    };
  }
};
</script>

<style lang="scss">
.cookie {
  position: fixed !important;
  width: 100%;
  left: 0;
  bottom: 0;
  z-index: 9999;
  margin: 0 !important;
  padding: 0 !important;
}
</style>
