


























































import { Component, Vue } from "vue-property-decorator";

@Component
export default class BottomBar extends Vue {
  navDrawer = null;

  socialLinks = [
    {
      icon: "fab fa-twitter",
      href: "https://twitter.com/"
    },
    {
      icon: "fab fa-facebook-f",
      href: "http://fb.me/"
    },
    {
      icon: "fab fa-linkedin-in",
      href: "https://www.linkedin.com/company/"
    },
    {
      icon: "fab fa-instagram",
      href: "https://www.instagram.com/dijivatandas"
    },
    {
      icon: "fas fa-envelope",
      href: "mailto:iletisim@dijivatandas.org"
    }
  ];

  menuItems = [
    {
      title: "Terms of Use",
      to: { name: "terms-of-use" }
    },
    {
      title: "Privacy Policy",
      to: { name: "privacy-policy" }
    },
    {
      title: "Personal Data Protection",
      to: { name: "personal-data-protection" }
    }
  ];
}
